export const categories = [
	{
		name: 'Услуги',
		subcategories: [
			'Запросы на услуги',
			'Исполнители услуг',
			'Обучение',
			'Транспорт, перевозки',
			'Уборка',
			'Ремонт и отделка',
			'Строительство',
			'Мастер на час',
			'Деловые услуги',
			'Бытовые услуги',
			'Ремонт и обслуживание техники',
			'Праздники и мероприятия',
			'Доставка',
			'Благоустройство',
			'Другое',
		],
	},
	{
		name: 'Автотехника',
		subcategories: [
			'Автомобили',
			'Мотоциклы',
			'Спецтехника',
			'Водный транспорт',
		],
	},
	{
		name: 'Автотовары',
		subcategories: ['Запчасти', 'Аксессуары для авто'],
	},
	{
		name: 'Вакансии',
		subcategories: ['Поиск работы', 'Поиск работника'],
	},
	{
		name: 'Электроника',
		subcategories: [
			'Аксессуары',
			'Фототехника',
			'Компьютерная техника',
			'Оргтехника',
			'Телефоны',
		],
	},
	{
		name: 'Недвижимость',
		subcategories: [
			'Покупка жилья',
			'Аренда жилья',
			'Аренда площадей',
			'Коммерческая недвижимость',
		],
	},
	{
		name: 'Одежда ',
		subcategories: ['Аксессуары', 'Вещи для детей'],
	},
	{
		name: 'Для дома и хозяйства ',
		subcategories: ['Стройматериалы', 'Животные', 'Мебель и интерьер', 'Сантехника', 'Красота и здоровье'],
	},
	{
		name: 'Рукоделие',
		subcategories: ['Разное'],
	},
]
