import { FC } from 'react'

const UserAcception: FC = () => {
	return (
		<div className='mt-14'>
			<h1 className='text-3xl text-center'>Пользовательское соглашение</h1>
			<p className='mt-7 '>
				Общие положения Настоящее Пользовательское соглашение (далее -
				Соглашение) относится к сайту «МИЛЬЕН», расположенному в сети Интернет
				по адресу: АДРЕС САЙТА. 1.2. Сайт «МИЛЬЕН» (далее - Сайт) является
				собственностью Индивидуального предпринимателя (ОГРН:, ИНН:, адрес
				регистрации: ЮР.АДРЕС) 1.3. Настоящее Соглашение регулирует отношения
				между Администрацией сайта «МИЛЬЕН» (далее - Администрация сайта) и
				Пользователем данного Сайта. 1.7. В соответствии со статьей 435
				Гражданского кодекса РФ настоящее Пользовательское соглашение признается
				офертой. В соответствии со статьей 438 Гражданского кодекса РФ
				безусловным принятием (акцептом) условий настоящего Соглашения считается
				факт регистрации на Сайте. Настоящее Соглашение, заключаемое путем
				акцепта настоящей оферты, не требует двустороннего подписания и
				действительно в электронном виде. Термины и определения, используемые в
				Соглашении В Соглашении, если из текста Соглашения прямо не вытекает
				иное, следующие слова и выражения будут иметь указанные ниже значения:
				2.1. Сайт – совокупность программных и аппаратных средств для ЭВМ,
				обеспечивающих публикацию для всеобщего обозрения информации и данных,
				объединенных общим целевым назначением, посредством технических средств,
				применяемых для связи между ЭВМ в сети Интернет. 2.2. Администрация
				сайта - ________________________________ 2.2. Контентом Сайта признаются
				все объекты, размещенные на Сайте, в том числе элементы дизайна, текст,
				графические изображения, иллюстрации, видео, скрипты, программы, музыка,
				звуки и другие объекты и их подборки. Администрация Сайта является
				обладателем исключительных прав на использование Сайта, включая весь
				контент Сайта. 2.3. Пользователь – пользователь сети Интернет, в
				частности Сайта, имеющий свою личную страницу (профиль/аккаунт). 2.4.
				Профиль/аккаунт (Профиль) – личная страница Пользователя на Сайте,
				доступная всем Пользователям Сайта. 2.5. Учетная информация – уникальное
				имя Пользователя (логин) и пароль для входа на Сайт, указываемые
				Пользователем при регистрации на Сайте. 2.6. Модерация – просмотр
				Владельцем Сайта размещаемой (или планируемой к размещению –
				Премодерация) на Сайте Пользователем информации на предмет ее
				соответствия положениям настоящего Соглашения. Предмет Соглашения 3.1.
				Администрация Сайта оказывает Пользователю услуги по предоставлению
				доступа к сервисам Сайта, при этом обязательным условием оказания
				Администрацией Сайта услуг в соответствии с настоящим Соглашением
				является принятие, соблюдение Пользователем и применение к отношениям
				Сторон требований и положений, определенных настоящим Соглашением.
				Пользователю предоставляется доступ к содержащимся на Сайте Товарам
				и/или оказываемым услугам. Получая доступ к Сайту Пользователь считается
				присоединившимся к настоящему Соглашению. 3.2. Администрация Сайта
				оставляет за собой право в любое время изменять добавлять или удалять
				пункты настоящего Соглашения и всех его неотъемлемых частей без
				согласования с Пользователем с уведомлением последнего посредством
				размещения на Сайте новой редакции Соглашения или какой-либо его
				неотъемлемой части, подвергшейся изменениям. Принимая во внимание, что
				информационная рассылка может быть признана Сторонами спамом,
				Пользователь обязуется не менее одного раза в месяц знакомиться с
				содержанием Соглашения, размещенного на Сайте, в целях своевременного
				ознакомления с его изменениями. Новая редакция Соглашения и/или
				какой-либо его неотъемлемой части вступает в силу с момента
				опубликования на Сайте, если иной срок вступления изменений в силу не
				определен Владельцем Сайта при их опубликовании. Действующая редакция
				Соглашения и всех приложений к нему всегда находится на Сайте в
				публичном доступе по адресу: ____________________________. 3.3.
				Использование Сайта Пользователем означает принятие Соглашения и
				изменений, внесенных в настоящее Соглашение. 3.4. Пользователь несет
				персональную ответственность за проверку настоящего Соглашения на
				наличие изменений в нем. 3.5. Использование материалов и сервисов Сайта
				регулируется нормами действующего законодательства Российской Федерации
				Права и обязанности Администрации Сайта 4.1. Администрация Сайта
				обязуется: 4.1.1. Оказывать Пользователю услуги, указанные в пункте 3.1
				настоящего Соглашения. 4.1.2. Предоставлять Пользователю возможность
				самостоятельно публиковать информацию о самом себе. 4.1.3. Не разглашать
				третьим лицам Учетную информацию Пользователя. 4.1.4. Обеспечивать
				круглосуточную доступность сервера, на котором расположен Сайт, за
				исключением времени проведения профилактических работ. 4.2.
				Администрация Сайта имеет право: 4.2.1. В случае нарушения Пользователем
				условий Соглашения направить Пользователю предупреждение, содержащее
				перечень нарушений. В случае если Пользователь не устранит нарушения в
				течение одного дня с момента направления ему уведомления или повторно
				нарушит указанные условия или иные условия, Администрация Сайта имеет
				право в одностороннем порядке отказаться от исполнения Соглашения,
				заблокировать доступ Пользователей к Профилю Пользователя и аннулировать
				Профиль Пользователя. 4.2. 2. Осуществлять Модерацию в случаях, когда
				информация, размещаемая Пользователем, пропагандирует ненависть и/или
				дискриминацию людей по расовому, этническому, половому, религиозному,
				социальному признакам; пропагандирует употребление наркотических и
				прочих средств, наносящих вред здоровью; призывает к бесчеловечному
				обращению с животными; нарушает права национальных и иных меньшинств;
				содержит призывы к насильственным действиям; нарушает права авторов и
				иных владельцев интеллектуальных прав. 4.2.3. Распоряжаться
				статистической информацией, связанной с функционированием Сайта, а также
				информацией Пользователей для обеспечения адресного показа рекламной
				информации различным аудиториям Пользователей Сайта. 4.2.4. Направлять
				Пользователю информацию о развитии Сайта и его сервисов; отправлять
				sms-сообщения с целью информирования о кодах активации, проведения
				опросов о качестве работы Сайта, предоставления и запросов прочей
				информации, а также рекламировать собственную деятельность и услуги.
				4.2.5. Изменять правила пользования Сайтом, а также изменять содержание
				данного Сайта. Изменения вступают в силу с момента публикации новой
				редакции Соглашения на Сайте. Права и обязанности Пользователя 5.1.
				Пользователь обязуется: 5.1.1. Полностью ознакомиться с условиями
				настоящего Соглашения до момента регистрации на Сайте. 5.1.2. Соблюдать
				все условия настоящего Соглашения. 5.1.3. Не передавать сведения о
				других Пользователях, полученные посредством Сайта, третьим лицам.
				5.1.4. Не передавать свою Учетную информацию третьим лицам. 5.1. 5. Не
				размещать на Сайте персональные данные других лиц, а также не
				использовать персональные данные других Пользователей или Пользователей
				каким-либо образом, не соответствующим требованиям законодательства РФ,
				в противоправных или противозаконных целях, в целях извлечения выгоды и
				любых иных целях, не соответствующих целям создания Сайта. 5.1.6. Не
				размещать в Профиле информацию и объекты (включая ссылки на них),
				которые могут нарушать права и интересы других лиц. 5.1.7. Не
				регистрироваться в качестве Пользователя от имени или вместо другого
				лица или регистрироваться от имени группы (объединения) лиц или
				юридического лица/ индивидуального предпринимателя в качестве
				Пользователя. 5.1.8. Не загружать, не хранить, не публиковать, не
				распространять любую информацию, которая: – содержит угрозы,
				дискредитирует, оскорбляет, порочит честь и достоинство или деловую
				репутацию или нарушает неприкосновенность частной жизни других
				Пользователей или третьих лиц; – нарушает права несовершеннолетних лиц;
				– является вульгарной или непристойной, содержит нецензурную лексику,
				содержит порнографические изображения и тексты или сцены сексуального
				характера с участием несовершеннолетних; – содержит сцены насилия либо
				бесчеловечного обращения с животными; – содержит описание средств и
				способов суицида, любое подстрекательство к его совершению; –
				пропагандирует и/или способствует разжиганию расовой, религиозной,
				этнической ненависти или вражды, пропагандирует фашизм или идеологию
				расового превосходства; – содержит экстремистские материалы; –
				пропагандирует преступную деятельность или содержит советы, инструкции
				или руководства по совершению преступных действий; – содержит информацию
				ограниченного доступа, включая, но не ограничиваясь, государственную и
				коммерческую тайну, информацию о частной жизни третьих лиц; – содержит
				рекламу или описывает привлекательность употребления алкоголя и/или
				наркотических веществ, в том числе «цифровых наркотиков» (звуковых
				файлов, оказывающих воздействие на мозг человека за счет бинауральных
				ритмов), информацию о распространении наркотиков, рецепты их
				изготовления и советы по употреблению; – носит мошеннический характер; –
				а также нарушает иные права и интересы граждан и юридических лиц или
				требования законодательства РФ. 5.1.9. Не использовать программное
				обеспечение и не осуществлять действия, направленные на нарушение
				нормального функционирования Сайта и его сервисов или персональных
				страниц Пользователей, не загружать, не хранить, не публиковать, не
				распространять и не предоставлять доступ или иным образом использовать
				вирусы, трояны и другие вредоносные программы; не использовать без
				специального на то разрешения Администрации Сайта автоматизированные
				скрипты (программы) для сбора информации на Сайте и (или) взаимодействия
				с Сайтом и его сервисами. 5.1. 10. Не пытаться получить доступ к логину
				и паролю другого Пользователя, в том числе включая, но не ограничиваясь,
				обман, взлом Профилей других Пользователей и прочее. 5.2. Пользователю
				запрещено: 5.2.1. Осуществлять незаконные сбор и обработку персональных
				данных других Пользователей. 5. 2.2. Осуществлять доступ к каким-либо
				услугам иным способом, кроме как через интерфейс, предоставленный
				Администрацией Сайта, за исключением случаев, когда такие действия были
				прямо разрешены Пользователю в соответствии с отдельным соглашением с
				Администрацией Сайта. 5.2.3. Воспроизводить, дублировать, копировать,
				продавать, осуществлять торговые операции и перепродавать услуги для
				каких-либо целей, за исключением случаев, когда такие действия были
				прямо разрешены Пользователю в соответствии с условиями отдельного
				соглашения с Администрацией Сайта. 5.2.4. Размещать коммерческую и
				политическую рекламу вне специальных разделов Сайта, установленных
				Администрацией Сайта. 5.2.5. Размещать любую информацию, которая, по
				мнению Администрации Сайта, является нежелательной, ущемляет интересы
				Пользователей или по другим причинам является нежелательной для
				размещения на Сайте. 5.3. Пользователь имеет право: 5.3.1. Круглосуточно
				получать доступ к серверу, на котором расположен Сайт, за исключением
				времени проведения профилактических работ. 5.3.2. Самостоятельно
				изменять пароль, не уведомляя об этом Администрацию Сайта. 5.3.3.
				Самостоятельно редактировать ранее размещенную о себе информацию на
				Сайте. 5.3.4. Обратиться в службу поддержки для блокирования своего
				Профиля (при отсутствии действующих демодоступов и подписок). 5.4.
				Пользователь согласен с тем, что, осуществляя доступ к Сайту и пользуясь
				его контентом, он: 5.4.1. Выражает свое безоговорочное согласие со всеми
				условиями настоящего Соглашения и обязуется их соблюдать или прекратить
				использование Сайта. 5.4.2. Получает личное неисключительное и
				непередаваемое право использовать контент Сайта на одном компьютере при
				условии, что ни сам Пользователь, ни любые иные лица при содействии
				Пользователя не будут копировать или изменять программное обеспечение;
				создавать программы, производные от программного обеспечения; проникать
				в программное обеспечение с целью получения кодов программ; осуществлять
				продажу, уступку, сдачу в аренду, передачу третьим лицам в любой иной
				форме прав в отношении программного обеспечения сервисов,
				предоставляемых Сайтом. Персональная информация 6.1. Администрация сайта
				с уважением и ответственностью относится к конфиденциальной информации
				любого лица, ставшего посетителем этого сайта. Принимая это Соглашение
				Пользователь дает согласие на сбор, обработку и использование
				определенной информации о Пользователе в соответствии с положениями
				ФЗ-152 "О защите персональных данных". Кроме того, пользователь дает
				согласие, что Администрация сайта может собирать, использовать,
				передавать, обрабатывать и поддерживать информацию, связанную с
				аккаунтом Пользователя с целью предоставления соответственных услуг.
				6.2. Администрация сайта обязуется осуществлять сбор только той
				персональной информации, которую Потребитель предоставляет добровольно в
				случае, когда информация нужна для предоставления (улучшения) услуг
				Потребителю. 6.3. Администрация сайта собирает как основные персональные
				данные, такие как имя, фамилия, отчество, адрес и электронный адрес, так
				и вторичные (технические) данные - файлы cookies, информация о
				соединениях и системная информация. 6.4. Пользователь соглашается с тем,
				что конфиденциальность переданных через Интернет данных не гарантирована
				в случае, если доступ к этим данным получен третьими лицами вне зоны
				технический средств связи, подвластных Администрации сайта,
				Администрация сайта не несет ответственности за ущерб, нанесенный таким
				доступом. 6.5. Администрация сайта может использовать любую собранную
				через Сайт информацию с целью улучшения содержания интернет-сайта, его
				доработки, передачи информации Пользователю (по запросам), для
				маркетинговых или исследовательских целей, а также для других целей, не
				противоречащим положениям действующего законодательства РФ. Регистрация
				Пользователя 7.1. В целях пользования услугами, предоставляемыми
				Администрацией Сайта по настоящему Соглашению, Пользователь должен
				пройти обязательную процедуру регистрации на Сайте по адресу
				__________________. Регистрация Пользователя на Сайте является
				бесплатной, добровольной. 7.2. При регистрации на Сайте Пользователь
				обязан предоставить Администрации Сайта необходимую достоверную и
				актуальную информацию для формирования Профиля, включая уникальные для
				каждого Пользователя логин (адрес электронной почты) и пароль доступа к
				Сайту, а также фамилию и имя. Регистрационная форма Сайта может
				запрашивать у Пользователя дополнительную информацию. 7.3. После
				предоставления информации, указанной в пункте 7.2 настоящего Соглашения,
				Пользователю необходимо подтвердить регистрацию путем выражения своего
				желания через нажатие на ссылку подтверждения регистрации в сообщении,
				отправленном на указанную им электронную почту. 7.4. По завершении
				процесса регистрации Пользователь становится обладателем Учетной
				информации Пользователя, что означает, что он несет ответственность за
				безопасность Учетной информации, а также за все, что будет сделано на
				Сайте под Учетной информацией Пользователя. Пользователь обязан
				немедленно уведомить Администрацию Сайта о любом случае
				несанкционированного доступа к Сайту, то есть осуществленного третьим
				лицом без согласия и ведома Пользователя и/или о любом нарушении
				безопасности Учетной информации Пользователя. Администрация Сайта не
				несет ответственности за возможную потерю или порчу данных, которая
				может произойти из-за нарушения Пользователем положений настоящего
				пункта Соглашения. 7.5. Для начала работы с Сайтом Пользователь должен
				ввести в соответствующем разделе Сайта свою Учетную информацию. 7.6.
				Если Пользователем не доказано обратное, любые действия, совершенные с
				использованием его логина и пароля, считаются совершенными
				соответствующим Пользователем. В случае несанкционированного доступа к
				логину и паролю и/или Профилю Пользователя или распространения логина и
				пароля Пользователь обязан незамедлительно сообщить об этом Владельцу
				Сайта в установленном порядке. Стоимость услуг и порядок расчетов 8.1.
				Стоимость выбранной пользователем услуги при заключении сделки в рамках
				Договора определяется исходя из характеристик услуги (вид услуги, состав
				пакета услуг и иные параметры). Стоимость выбранной услуги отображается
				в рублях в интерфейсе ____________ при оформлении заказа, если иное не
				предусмотрено условиями соответствующего сервиса или услуги. Оплата
				услуг осуществляется по ценам, действующим на момент совершения платежа.
				8.2. Вы можете совершить оплату услуг одним из способов, представленных
				в интерфейсе __________ в момент оформления заказа услуг, в том числе за
				счет ранее перечисленного аванса, либо в ином порядке, предусмотренном
				условиями соответствующего сервиса или правилами оказания услуги. 8.3.
				Пользователь с профилем юридического лица или индивидуального
				предпринимателя должен осуществлять оплату услуг по Договору безналичным
				переводом денежных средств на расчетный счет компании________ на
				основании выставленного счета. Допускается также оплата с использованием
				корпоративной банковской карты, оформленной на соответствующее
				юридическое лицо или индивидуального предпринимателя. 8.4. Администрация
				сайта предоставляет пользователю информацию обо всех совершаемых им
				платежах в специальном информационно-аналитическом разделе профиля
				(далее – «Кошелек»). Кошелек предназначен для учета поступающих от
				пользователя в качестве аванса денежных средств, которые могут быть
				использованы для оплаты заказываемых в будущем услуг Компании, а также
				для предоставления иной, связанной с услугами Компании информации, в том
				числе о наличии бонусов, которые могут включаться в общий баланс
				Кошелька, о платежах, поступивших от пользователя в счет оплаты услуг
				компании_________ (в том числе в целях подтверждения оплаты). 8.5. При
				оплате услуг за счет ранее внесенного аванса, отраженного в Кошельке, с
				баланса Кошелька осуществляется списание соответствующей суммы денежных
				средств с учетом бонусов/скидок (при их наличии), если условия
				соответствующего сервиса допускают их использование. Совершение такой
				оплаты возможно только при наличии на балансе Кошелька суммы равной или
				большей суммарной стоимости заказанных услуг. 8.6. Оплата безналичным
				переводом на основании выставленного счета для пользователей с профилем
				юридического лица или индивидуального предпринимателя, и/или
				сформированной квитанции для частных пользователей должна производиться
				с указанием номера профиля пользователя, а также иных реквизитов,
				идентифицирующих платеж, в том числе наименования и ИНН для
				пользователей с профилем юридического лица или индивидуального
				предпринимателя. При оплате таким способом пользователь обязан внести в
				платежный документ точные данные, указанные в назначении платежа в
				выставленном ему счете на оплату или квитанции. При отсутствии или
				неправильном указании перечисленных в настоящем пункте данных, Компания
				вправе считать, что обязательства по оплате не выполнены пользователем
				надлежащим образом, либо самостоятельно идентифицировать платеж согласно
				имеющимся данным (в том числе данным собственного учета). Администрация
				сайта не отвечает за убытки, которые могут возникнуть у пользователя или
				третьих лиц в случае неправильного указания назначения платежа. 8.7.
				Заказ услуг должен быть оплачен полностью одним способом платежа. 8.8. В
				случае отмены услуги до начала ее предоставления, прекращения услуги по
				любому иному основанию, неиспользованная сумма (при её наличии)
				зачисляется на Кошелек пользователя, независимо от того, каким способом
				была совершена ранее оплата услуг. 8.9. В рамках проведения
				стимулирующих мероприятий или в иных случаях по единоличному решению
				Администрации вам могут быть предоставлены бонусы. Использование бонусов
				осуществляется в виде предоставления скидки на стоимость услуг при их
				оплате. Если на балансе Кошелька есть бонусы, при оказании услуг оплата
				за услуги осуществляется пропорционально с использованием денежных
				средств и бонусов. Если Пользователь досрочно отказывается от
				использования услуги, вся сумма ранее предоставленных бонусов
				аннулируется и не подлежит восстановлению. По истечении 12 месяцев с
				даты последнего платежа с использованием Кошелька неиспользованные
				пользователем бонусы могут аннулироваться, если для их использования не
				установлен более короткий срок. В любом случае при отказе от
				использования услуг в Кошелек пользователя может быть перечислен только
				остаток денежных средств, без учета бонусов. Предоставляемые по
				единоличному решению Администрации бонусы не могут быть обменяны,
				переданы третьим лицам, не могут быть использованы для иных услуг, или
				заменены на денежный эквивалент. Бонусы, аннулированные в связи с
				окончанием срока их действия или по иным причинам, не могут быть
				компенсированы ни в каком виде, в том числе в случае их частичного
				использования. 8.10. Факт предоставления пользователю услуг и их объем
				определяются на основании статистических данных учетной системы
				Администрации. 8.11. В отношении услуг, которые Компания оказывает
				пользователям с профилями юридического лица или индивидуального
				предпринимателя в целях исполнения требований законодательства
				Российской Федерации о бухгалтерском учете, Администрация ежемесячно
				направляет по адресу электронной почты пользователя или предоставляет
				возможность выгрузки в профиле копии универсального передаточного
				документа, включающего в себя счет-фактуру и акт (далее – «УПД») за
				отчетный месяц. Оригинал такого документа может быть направлен почтой по
				адресу, указанному в профиле. 8.12. Если Администрация не получает
				письменные мотивированные возражения пользователя относительно оказанных
				услуг в течение 5 дней с даты завершения отчетного месяца (в том числе
				по причине отказа представителя пользователя от получения электронной
				копии УПД или других документов или указания пользователем ненадлежащего
				адреса для получения документов) услуги за отчетный период, указанные в
				УПД, считаются оказанными Компанией надлежащим образом и принятыми
				пользователем в полном объеме. 8.13. Ответственность за получение любых
				документов (в том числе УПД, сообщений, уведомлений) вышеуказанными
				способами лежит на пользователе. Администрация не несет ответственности
				за задержку или неполучение пользователем документов, если это явилось
				результатом неисправности систем связи, действия/бездействия
				провайдеров, операторов связи, утраты пользователем доступа к профилю
				или иных обстоятельств, находящихся вне зоны контроля Компании. 8.14.
				Наименование, юридический адрес, почтовый адрес, ИНН, КПП, контактная и
				прочая информация в платежных документах, УПД и других документах,
				формируемых Администрацией, указываются в соответствии с реквизитами
				пользователя, указанными в его профиле. 8.15. Пользователь
				самостоятельно несет ответственность за правильность данных, указанных в
				профиле пользователя на сайте. При изменении реквизитов, вы обязаны
				незамедлительно внести соответствующие изменения в вашем профиле. Если
				вы не можете самостоятельно изменить данные в профиле, вы обязаны
				уведомить Администрацию о таких изменениях по установленной
				Администрацией форме с приложением, по запросу Администрации,
				подтверждающих изменения документов. 8.16. Администрация вправе
				проверять правильность указанных в профиле реквизитов пользователя путем
				сверки с данными, содержащимися в едином государственном реестре
				юридических лиц (индивидуальных предпринимателей) и, при наличии
				расхождений, корректировать соответствующие данные, используя в качестве
				идентификатора пользователя указанный им ИНН. Ответственность Сторон
				9.1. Пользователь самостоятельно определяет перечень организационных и
				программных (для ЭВМ) средств для сохранения в тайне своей Учетной
				информации и обеспечения санкционированного доступа к ней. Владелец
				Сайта не несет ответственность за убытки, причиненные Пользователю в
				результате разглашения третьим лицам Учетной информации Пользователя,
				произошедшего не по вине Администрации Сайта. Если любое лицо, помимо
				Пользователя, авторизуется на Сайте, используя Учетную информацию
				Пользователя, то все действия, совершенные таким лицом, будут считаться
				совершенными этим Пользователем. Пользователь самостоятельно несет
				ответственность за все действия, совершенные им на Сайте, а также за все
				действия, совершенные на Сайте любыми иными лицами с использованием
				Учетной информации Пользователя. 9.2. Администрация Сайта не
				гарантирует, что программное обеспечение Сайта не содержит ошибок и/или
				компьютерных вирусов или посторонних фрагментов кода. Администрация
				Сайта предоставляет возможность Пользователю пользоваться программным
				обеспечением Сайта «как оно есть», без каких-либо гарантий со стороны
				Администрации Сайта. Администрация сайта не несет никакой
				ответственности за любые ошибки, опечатки и неточности, которые могут
				быть обнаружены в материалах, содержащихся на данном Сайте.
				Администрация сайта прикладывает все необходимые усилия, чтобы
				обеспечить точность и достоверность представляемой на Сайте информации.
				9.3. Администрация Сайта не несет ответственности за убытки, причиненные
				Пользователю в результате сообщения другим Пользователем недостоверной
				информации, а также причиненные действиями (бездействием) другого
				Пользователя. Администрация Сайта не гарантирует, что информация,
				содержащаяся в Профилях Пользователей, является достоверной и полной.
				9.4. Администрация Сайта прилагает все возможные усилия для обеспечения
				нормальной работоспособности Сайта, однако не несет ответственности за
				неисполнение или ненадлежащее исполнение обязательств по Соглашению, а
				также возможные убытки, возникшие в том числе, но не ограничиваясь, в
				результате: – неправомерных действий Пользователей, направленных на
				нарушения информационной безопасности или нормального функционирования
				Сайта; – сбоев в работе Сайта, вызванных ошибками в коде, компьютерными
				вирусами и иными посторонними фрагментами кода в программном обеспечении
				Сайта; – отсутствия (невозможности установления, прекращения и пр.)
				интернет-соединений между сервером Пользователя и сервером Сайта; –
				проведения государственными и муниципальными органами, а также иными
				организациями мероприятий в рамках Системы оперативно-разыскных
				мероприятий; – установления государственного регулирования (или
				регулирования иными организациями) хозяйственной деятельности
				коммерческих организаций в сети Интернет и/или установления указанными
				субъектами разовых ограничений, затрудняющих или делающих невозможным
				исполнение Соглашения; – других случаев, связанных с действиями
				(бездействием) Пользователей и/или других субъектов, направленными на
				ухудшение общей ситуации с использованием сети Интернет и/или
				компьютерного оборудования, существовавшей на момент заключения
				Соглашения, а также любых других действий, направленных на Сайт и на
				третьих лиц; – выполнения работ, указанных в пунктах 9.5 и 9.6
				настоящего Соглашения. 9.5. В случае наступления форс-мажорных
				обстоятельств, а также аварий или сбоев в программно-аппаратных
				комплексах третьих лиц, сотрудничающих с Администрацией Сайта, или
				действий (бездействия) третьих лиц, направленных на приостановку или
				прекращение функционирования Сайта, возможна приостановка работы Сайта
				без предварительного уведомления Пользователя. 9.6. Владелец Сайта не
				несет ответственности за нарушение Пользователем настоящих Правил и
				оставляет за собой право по своему собственному усмотрению, а также при
				получении информации от других пользователей либо третьих лиц о
				нарушении Пользователем настоящих Правил изменять (модерировать) или
				удалять любую публикуемую Пользователем информацию, нарушающую запреты,
				установленные настоящими Правилами (включая личные сообщения),
				приостанавливать, ограничивать или прекращать доступ Пользователя ко
				всем или к любому из разделов или сервисов Сайта в любое время по любой
				причине или без объяснения причин, с предварительным уведомлением или
				без такового, не отвечая за любой вред, который может быть причинен
				таким действием. Владелец Сайта закрепляет за собой право удалить
				Профиль Пользователя и (или) приостановить, ограничить или прекратить
				доступ Пользователя к любому из сервисов Сайта, если обнаружит, что, по
				его мнению, Пользователь представляет угрозу для Сайта и (или) его
				Пользователей. Владелец Сайта не несет ответственности за осуществленное
				в соответствии с настоящими Правилами временное блокирование или
				удаление информации либо удаление персональной страницы (прекращение
				регистрации) Пользователя. Удаление Профиля Пользователя означает
				автоматическое удаление всей информации, размещенной на ней, а также
				всей информации Пользователя, введенной при регистрации на Сайте. После
				удаления персональной страницы Пользователь теряет права доступа к
				Сайту. 9.7. Ни одна из Сторон не несет ответственности за полное или
				частичное неисполнение любой из своих обязанностей, если неисполнение
				является следствием таких обстоятельств, как наводнение, пожар,
				землетрясение, другие стихийные бедствия, война или военные действия и
				другие обстоятельства непреодолимой силы, возникшие после заключения
				Соглашения и не зависящие от воли Сторон. Порядок разрешения споров и
				урегулирования претензий 10.1. В случае возникновения споров между
				Пользователем и Администрацией Сайта по вопросам, связанным с
				исполнением Соглашения, Стороны примут все меры к разрешению их путем
				переговоров между собой. Претензионный порядок разрешения споров
				обязателен. Претензии Пользователей по предоставляемым Услугам
				принимаются и рассматриваются Администрацией Сайта только в письменном
				виде и в порядке, предусмотренном настоящим Соглашением и действующим
				законодательством РФ. 10.2. Для разрешения споров, возникших между
				Пользователем и Администрацией Сайта в результате использования услуг,
				применяется следующий претензионный порядок. Пользователь, считающий,
				что его права нарушены из-за действий Администрации Сайта, направляет
				последнему претензию, содержащую суть предъявляемого требования,
				обоснование его предъявления, а также все данные Пользователя. Претензия
				также направляется Администрации Сайта в письменном виде посредством
				отправки по почте или по факсу; – в течение 5 (пяти) рабочих дней со дня
				получения претензии Администрация Сайта обязана изложить свою позицию по
				указанным в ней принципиальным вопросам и направить свой ответ по адресу
				электронной почты или почтовому адресу, указанному в претензии
				Пользователя; – в случае недостижения разрешения спора путем
				претензионного порядка спор подлежит рассмотрению в соответствии с
				пунктом 9.4 Соглашения; – Администрацией Сайта не рассматриваются
				анонимные претензии или претензии, не позволяющие идентифицировать
				Пользователя на основе предоставленных им при регистрации данных, или
				претензии, не содержащие данных, указанных в настоящем пункте настоящего
				Соглашения. 10.3. Для решения технических вопросов при определении вины
				Пользователя в результате его неправомерных действий при пользовании
				сетью Интернет и Сайтом, в частности, Администрация Сайта вправе
				самостоятельно привлекать компетентные организации в качестве экспертов.
				В случае установления вины Пользователя последний обязан возместить
				затраты на проведение экспертизы. 10.4. При недостижении согласия между
				Сторонами путем переговоров спор, вытекающий из настоящего Соглашения,
				подлежит рассмотрению в суде общей юрисдикции по месту нахождения
				Администрации Сайта. Прочие условия 11.1. Настоящее Соглашение вступает
				в силу с момента акцепта настоящей оферты Пользователем и заключается на
				неопределенный срок. 11.2. Настоящее Соглашение является офертой и в
				силу действующего гражданского законодательства РФ Администрация Сайта
				имеет право на отзыв оферты в соответствии со статьей 436 Гражданского
				кодекса РФ. В случае отзыва настоящего соглашения Администрацией Сайта
				настоящее соглашение считается прекращенным с момента отзыва. Отзыв
				осуществляется путем размещения соответствующей информации на Сайте.
				11.3. Положения настоящего Соглашения устанавливаются, изменяются и
				отменяются Администрацией Сайта в одностороннем порядке без
				предварительного уведомления. С момента размещения на Сайте новой
				редакции Соглашения предыдущая редакция считается утратившей свою силу.
				В случае существенного изменения положений настоящего Соглашения
				Администрация Сайта извещает об этом Пользователей путем размещения на
				Сайте соответствующего сообщения. 11.4. Если Пользователь не согласен с
				условиями настоящего Соглашения, то он должен немедленно удалить свой
				Профиль с Сайта, в противном случае продолжение использования
				Пользователем Сайта означает, что Пользователь согласен с условиями
				Соглашения. 11.5. Вопросы, не урегулированные настоящим Соглашением,
				подлежат разрешению в соответствии с законодательством РФ.
				ПОЛЬЗОВАТЕЛЬСКОЕ СОГЛАШЕНИЕ 1. ОБЩИЕ ПОЛОЖЕНИЯ 1.1. Настоящее
				Пользовательское соглашение (далее - Соглашение) относится к сайту
				«МИЛЬЕН», расположенному по адресу АДРЕС САЙТА. 1.2. Сайт «МИЛЬЕН»
				(далее - Сайт) является собственностью юридического лица ОРГАНИЗАЦИЯ
				(ОГРН: НОМЕР, ИНН: НОМЕР, адрес регистрации: ЮР.АДРЕС) 1.3. Настоящее
				Соглашение регулирует отношения между Администрацией сайта «МИЛЬЕН»
				(далее - Администрация сайта) и Пользователем данного Сайта. 1. 4.
				Администрация сайта оставляет за собой право в любое время изменять,
				добавлять или удалять пункты настоящего Соглашения без уведомления
				Пользователя. 1.5. Использование Сайта Пользователем означает принятие
				Соглашения и изменений, внесенных в настоящее Соглашение. 1.6.
				Пользователь несет персональную ответственность за проверку настоящего
				Соглашения на наличие изменений в нем. 2. ОПРЕДЕЛЕНИЯ ТЕРМИНОВ 2.1.
				Перечисленные ниже термины имеют для целей настоящего Соглашения
				следующее значение: 2.1.1 «МИЛЬЕН» - Интернет-ресурс, расположенный на
				доменном имени АДРЕС САЙТА, осуществляющий свою деятельность посредством
				Интернет-ресурса и сопутствующих ему сервисов (далее - Сайт). 2.1.2.
				«МИЛЬЕН» - сайт, содержащий информацию о Товарах и/или Услугах и/или
				Иных ценностях для пользователя, Продавце и/или Исполнителе услуг,
				позволяющий осуществить выбор, заказ и (или) приобретение Товара, и/или
				получение услуги. 2.1.3. Администрация сайта - уполномоченные сотрудники
				на управления Сайтом, действующие от имени юридического лица
				ОРГАНИЗАЦИЯ. 2. 1.4. Пользователь сайта (далее - Пользователь) - лицо,
				имеющее доступ к Сайту, посредством сети Интернет и использующее Сайт.
				2.1.5. Содержание сайта (далее - Содержание) - охраняемые результаты
				интеллектуальной деятельности, включая тексты литературных произведений,
				их названия, предисловия, аннотации, статьи, иллюстрации, обложки,
				музыкальные произведения с текстом или без текста, графические,
				текстовые, фотографические, производные, составные и иные произведения,
				пользовательские интерфейсы, визуальные интерфейсы, названия товарных
				знаков, логотипы, программы для ЭВМ, базы данных, а также дизайн,
				структура, выбор, координация, внешний вид, общий стиль и расположение
				данного Содержания, входящего в состав Сайта и другие объекты
				интеллектуальной собственности все вместе и/или по отдельности,
				содержащиеся на сайте АДРЕС САЙТА. 3. ПРЕДМЕТ СОГЛАШЕНИЯ 3.1. Предметом
				настоящего Соглашения является предоставление Пользователю доступа к
				содержащимся на Сайте Товарам и/или оказываемым услугам. 3.1.1. Сайт
				предоставляет Пользователю следующие виды услуг (сервисов): •
				предоставление Пользователю возможности размещения сообщений,
				комментариев, рецензий Пользователей, выставления оценок контенту сайта;
				• ознакомление с товарами/услугами, размещенными на Сайте; • выбор и
				заказ товаров/услуг для осуществления последующей покупки или оформления
				на данном Сайте. 3.1.2. Под действие настоящего Соглашения подпадают все
				существующие (реально функционирующие) на данный момент услуги (сервисы)
				Сайта, а также любые их последующие модификации и появляющиеся в
				дальнейшем дополнительные услуги (сервисы). 3.2. Доступ к сайту
				предоставляется на бесплатной основе. 3.3. Настоящее Соглашение является
				публичной офертой. Получая доступ к Сайту Пользователь считается
				присоединившимся к настоящему Соглашению. 3.4. Использование материалов
				и сервисов Сайта регулируется нормами действующего законодательства
				Российской Федерации 4. ПРАВА И ОБЯЗАННОСТИ СТОРОН 4. 1. Администрация
				сайта вправе: 4.1.1. Изменять правила пользования Сайтом, а также
				изменять содержание данного Сайта. Изменения вступают в силу с момента
				публикации новой редакции Соглашения на Сайте. 4.2. Пользователь вправе:
				4.2.1. Пользоваться всеми имеющимися на Сайте услугами, а также
				приобретать любые Товары и/или Услуги, предлагаемые на Сайте. 4.2.2.
				Задавать любые вопросы, относящиеся к услугам сайта: • по телефону:
				ТЕЛЕФОН • по электронной почте: rumilien@gmail.com • через Форму
				обратной связи, расположенную по адресу: АДРЕС САЙТА 4.2.3. Пользоваться
				Сайтом исключительно в целях и порядке, предусмотренных Соглашением и не
				запрещенных законодательством Российской Федерации. 4.2.5. Требовать от
				администрации скрытия любой информации о пользователе. 4.2.6.
				Использовать информацию сайта в коммерческих целях без специального
				разрешения. 4.3. Пользователь Сайта обязуется: 4.3.1. Предоставлять по
				запросу Администрации сайта дополнительную информацию, которая имеет
				непосредственное отношение к предоставляемым услугам данного Сайта.
				4.3.2. Соблюдать имущественные и неимущественные права авторов и иных
				правообладателей при использовании Сайта. 4.3.3. Не предпринимать
				действий, которые могут рассматриваться как нарушающие нормальную работу
				Сайта. 4.3.4. Не распространять с использованием Сайта любую
				конфиденциальную и охраняемую законодательством Российской Федерации
				информацию о физических либо юридических лицах. 4.3.5. Избегать любых
				действий, в результате которых может быть нарушена конфиденциальность
				охраняемой законодательством Российской Федерации информации. 4.3.6. Не
				использовать Сайт для распространения информации рекламного характера,
				иначе как с согласия Администрации сайта. 4.3.7. Не использовать сервисы
				с целью: 4.3.7.1. нарушения прав несовершеннолетних лиц и (или)
				причинение им вреда в любой форме. 4.3.7. 2. ущемления прав меньшинств.
				4.3.7.3. представления себя за другого человека или представителя
				организации и (или) сообщества без достаточных на то прав, в том числе
				за сотрудников данного сайта. 4.3.7.4. введения в заблуждение
				относительно свойств и характеристик какого-либо Товара и/или услуги,
				размещенных на Сайте. 4.3.7.5. некорректного сравнения Товара и/или
				Услуги, а также формирования негативного отношения к лицам, (не)
				пользующимся определенными Товарами и/или услугами, или осуждения таких
				лиц. 4.3.7.6. загрузки контента, который является незаконным, нарушает
				любые права третьих лиц; пропагандирует насилие, жестокость, ненависть и
				(или) дискриминацию по расовому, национальному, половому, религиозному,
				социальному признакам; содержит недостоверные сведения и (или)
				оскорбления в адрес конкретных лиц, организаций, органов власти.
				4.3.7.7. побуждения к совершению противоправных действий, а также
				содействия лицам, действия которых направлены на нарушение ограничений и
				запретов, действующих на территории Российской Федерации. 4.3.8.
				Обеспечить достоверность предоставляемой информации 4.3.9. Обеспечивать
				сохранность личных данных от доступа третьих лиц. 4.4. Пользователю
				запрещается: 4.4.1. Использовать любые устройства, программы, процедуры,
				алгоритмы и методы, автоматические устройства или эквивалентные ручные
				процессы для доступа, приобретения, копирования или отслеживания
				содержания Сайта. 4.4.2. Нарушать надлежащее функционирование Сайта.
				4.4.3. Любым способом обходить навигационную структуру Сайта для
				получения или попытки получения любой информации, документов или
				материалов любыми средствами, которые специально не представлены
				сервисами данного Сайта. 4.4.4. Несанкционированный доступ к функциям
				Сайта, любым другим системам или сетям, относящимся к данному Сайту, а
				также к любым услугам, предлагаемым на Сайте. 4.4.4. Нарушать систему
				безопасности или аутентификации на Сайте или в любой сети, относящейся к
				Сайту. 4.4.5. Выполнять обратный поиск, отслеживать или пытаться
				отслеживать любую информацию о любом другом Пользователе Сайта. 4.4.6.
				Использовать Сайт и его Содержание в любых целях, запрещенных
				законодательством Российской Федерации, а также подстрекать к любой
				незаконной деятельности или другой деятельности, нарушающей права Сайта
				или других лиц. 5. ИСПОЛЬЗОВАНИЕ САЙТА 5.1. Сайт и Содержание, входящее
				в состав Сайта, принадлежит и управляется Администрацией сайта. 5.2.
				Содержание Сайта защищено авторским правом, законодательством о товарных
				знаках, а также другими правами, связанными с интеллектуальной
				собственностью, и законодательством о недобросовестной конкуренции. 5.3.
				Настоящее Соглашение распространяет свое действия на все дополнительные
				положения и условия о покупке Товара и/или оказанию услуг,
				предоставляемых на Сайте. 5.4. Информация, размещаемая на Сайте не
				должна истолковываться как изменение настоящего Соглашения. 5.5.
				Администрация сайта имеет право в любое время без уведомления
				Пользователя вносить изменения в перечень Товаров и услуг, предлагаемых
				на Сайте, и (или) их цен. 5.6. Документ указанный в пункте 5.7.
				настоящего Соглашения регулирует в соответствующей части и
				распространяют свое действие на использование Пользователем Сайта. 5.7.
				Политика конфиденциальности: ССЫЛКА НА ДОКУМЕНТ 5.8. Любой из
				документов, перечисленных в пункте 5.7 настоящего Соглашения может
				подлежать обновлению. Изменения вступают в силу с момента их
				опубликования на Сайте. 6. ОТВЕТСТВЕННОСТЬ 6.1. Любые убытки, которые
				Пользователь может понести в случае умышленного или неосторожного
				нарушения любого положения настоящего Соглашения, а также вследствие
				несанкционированного доступа к коммуникациям другого Пользователя,
				Администрацией сайта не возмещаются. 6.2. Администрация сайта не несет
				ответственности за: 6.2.1. Задержки или сбои в процессе совершения
				операции, возникшие вследствие непреодолимой силы, а также любого случая
				неполадок в телекоммуникационных, компьютерных, электрических и иных
				смежных системах. 6.2.2. Действия систем переводов, банков, платежных
				систем и за задержки связанные с их работой. 6.2.3. Надлежащее
				функционирование Сайта, в случае, если Пользователь не имеет необходимых
				технических средств для его использования, а также не несет никаких
				обязательств по обеспечению пользователей такими средствами. 7.
				НАРУШЕНИЕ УСЛОВИИ ПОЛЬЗОВАТЕЛЬСКОГО СОГЛАШЕНИЯ 7.1. Администрация сайта
				имеет право раскрыть информацию о Пользователе, если действующее
				законодательство Российской Федерации требует или разрешает такое
				раскрытие. 7.2. Администрация сайта вправе без предварительного
				уведомления Пользователя прекратить и (или) заблокировать доступ к
				Сайту, если Пользователь нарушил настоящее Соглашение или содержащиеся в
				иных документах условия пользования Сайтом, а также в случае прекращения
				действия Сайта либо по причине технической неполадки или проблемы. 7.3.
				Администрация сайта не несет ответственности перед Пользователем или
				третьими лицами за прекращение доступа к Сайту в случае нарушения
				Пользователем любого положения настоящего Соглашения или иного
				документа, содержащего условия пользования Сайтом. 8. РАЗРЕШЕНИЕ СПОРОВ
				8.1. В случае возникновения любых разногласий или споров между Сторонами
				настоящего Соглашения обязательным условием до обращения в суд является
				предъявление претензии (письменного предложения о добровольном
				урегулировании спора). 8.2. Получатель претензии в течение 30
				календарных дней со дня ее получения, письменно уведомляет заявителя
				претензии о результатах рассмотрения претензии. 8.3. При невозможности
				разрешить спор в добровольном порядке любая из Сторон вправе обратиться
				в суд за защитой своих прав, которые предоставлены им действующим
				законодательством Российской Федерации. 8.4. Любой иск в отношении
				условий использования Сайта должен быть предъявлен в течение 5 дней
				после возникновения оснований для иска, за исключением защиты авторских
				прав на охраняемые в соответствии с законодательством материалы Сайта.
				При нарушении условий данного пункта любой иск оставляется судом без
				рассмотрения. 9. ДОПОЛНИТЕЛЬНЫЕ УСЛОВИЯ 9.1. Администрация сайта не
				принимает встречные предложения от Пользователя относительно изменений
				настоящего Пользовательского соглашения. 9.2. Отзывы Пользователя,
				размещенные на Сайте, не являются конфиденциальной информацией и могут
				быть использованы Администрацией сайта без ограничений. ОБРАЗЕЦ
				ПОЛЬЗОВАТЕЛЬСКОГО СОГЛАШЕНИЯ ДЛЯ САЙТА №3 Пользовательское соглашение
				Условия использования сайта 1.1. Данное пользовательское соглашение
				(именуемое в дальнейшем Соглашение) представляет собой оферту условий по
				пользованию веб-сайтом __________ (далее – Сайт), в лице Администрации
				сайта и физическим лицом (в том числе представителями юридических лиц)
				(именуемым в дальнейшем Пользователь), и регламентирующее условия
				предоставления Пользователем информации для размещения на Сайте. 1.2.
				Пользователем Сайта считается любое физическое лицо, когда-либо
				осуществившее доступ к Сайту, достигшее возраста, допустимого для
				акцепта настоящего Соглашения. 1.3. Пользователь обязан полностью
				ознакомиться с настоящим Соглашением до момента регистрации на Сайте.
				Регистрация Пользователя на Сайте означает полное и безоговорочное
				принятие Пользователем настоящего Соглашения. В случае несогласия с
				условиями Соглашения, использование Сайта Пользователем должно быть
				немедленно прекращено. 1.4. Настоящее Соглашение может быть изменено
				и/или дополнено Администрацией Сайта в одностороннем порядке без
				какого-либо специального уведомления. Настоящие Правила являются
				открытым и общедоступным документом. 1.5. Соглашение предусматривает
				взаимные права и обязанности Пользователя и Администрации Сайта. 2.
				Порядок использования Сайта 2.1. Сайт позволяет Пользователю
				просматривать и загружать информацию с сайта исключительно для личного
				некоммерческого использования. Запрещается изменять материалы сайта,
				распространять для общественных или коммерческих целей. 2.2. При
				регистрации на сайте и оформлении заказов, Пользователь соглашается
				предоставить достоверную и точную информацию о себе и своих контактных
				данных. 2. 3. В процессе регистрации на сайте, Пользователь получает
				логин и пароль за безопасность, которых несет персональную
				ответственность. 2.4. Пользователь может обращаться к Администрации
				сайта с вопросами, претензиями, пожеланиями по улучшению работы, либо с
				какой-либо иной информацией. При этом Пользователь несет
				ответственность, что данное обращение не является незаконным,
				угрожающим, нарушает авторские права, дискриминацию людей по какому-либо
				признаку, а также содержит оскорбления либо иным образом нарушает
				действующее законодательство РФ. 3. Персональная информация Пользователя
				3.1. Администрация сайта с уважением и ответственностью относится к
				конфиденциальной информации любого лица, ставшего посетителем этого
				сайта. Принимая это Соглашение Пользователь дает согласие на сбор,
				обработку и использование определенной информации о Пользователе в
				соответствии с положениями ФЗ-152 "О защите персональных данных". Кроме
				того, пользователь дает согласие, что Администрация сайта может
				собирать, использовать, передавать, обрабатывать и поддерживать
				информацию, связанную с аккаунтом Пользователя с целью предоставления
				соответственных услуг. 3.2. Администрация сайта обязуется осуществлять
				сбор только той персональной информации, которую Потребитель
				предоставляет добровольно в случае, когда информация нужна для
				предоставления (улучшения) услуг Потребителю. 3.3. Администрация сайта
				собирает как основные персональные данные, такие как имя, фамилия,
				отчество, адрес и электронный адрес, так и вторичные (технические)
				данные - файлы cookies, информация о соединениях и системная информация.
				3.4. Пользователь соглашается с тем, что конфиденциальность переданных
				через Интернет данных не гарантирована в случае, если доступ к этим
				данным получен третьими лицами вне зоны технический средств связи,
				подвластных Администрации сайта, Администрация сайта не несет
				ответственности за ущерб, нанесенный таким доступом. 3.5. Администрация
				сайта может использовать любую собранную через Сайт информацию с целью
				улучшения содержания интернет-сайта, его доработки, передачи информации
				Пользователю (по запросам), для маркетинговых или исследовательских
				целей, а также для других целей, не противоречащим положениям
				действующего законодательства РФ. 4. На Сайте запрещены: 4.1. Призывы к
				насильственному изменению или свержению конституционного строя или к
				захвату государственной власти; призывы к погромам, поджогам,
				уничтожению имущества, захвату зданий или сооружений, насильственному
				выселению граждан; призывы к агрессии или к развязыванию военного
				конфликта. 4.2. Прямые и косвенные оскорбления кого-либо, в частности
				политиков, чиновников, журналистов, пользователей ресурса, в том числе
				по национальной, этнической, расовой или религиозной принадлежности, а
				также шовинистические высказывания. 4.3. Нецензурные высказывания,
				высказывания порнографического, эротического или сексуального характера.
				4.4. Любое оскорбительное поведение по отношению к авторам статей и всем
				участникам ресурса. 4.5. Высказывания, целью которых есть намеренное
				провоцирование резкой реакции других участников ресурса. 4.6. Реклама,
				коммерческие сообщения, а также сообщения, которые не имеют
				информационной нагрузки и не касаются тематики ресурса, если на такую
				рекламу или сообщение не получено специального разрешения от
				Администрации сайта. 4.7. Любые сообщения и прочие действия, которые
				запрещены законодательством РФ. 4.8. Выдача себя за другого человека или
				представителя организации и/или сообщества без достаточных на то прав, в
				том числе за сотрудников и владельцев Сайта, а также введения в
				заблуждение относительно свойств и характеристик каких-либо субъектов
				или объектов. 4.9. Размещение материалов, которые Пользователь не имеет
				права делать доступными по закону или согласно каким-либо контрактным
				отношениям, а также материалов, которые нарушают права на какой-либо
				патент, торговую марку, коммерческую тайну, копирайт или прочие права
				собственности и/или авторские и смежные с ним права третьей стороны.
				4.10. Размещение не разрешенной специальным образом рекламной
				информации, спама, схем "пирамид", "писем счастья"; материалов,
				содержащих компьютерные коды, предназначенные для нарушения, уничтожения
				либо ограничения функциональности любого компьютерного или
				телекоммуникационного оборудования или программ, для осуществления
				несанкционированного доступа, а также серийные номера к коммерческим
				программным продуктам, логины, пароли и прочие средства для получения
				несанкционированного доступа к платным ресурсам в Интернет. 4.11.
				Нарочное или случайное нарушения каких-либо применимых местных,
				государственных или международных нормативно – правовых актов. 5.
				Ограничение ответственности Администрации сайта 5.1. Администрация сайта
				не несет никакой ответственности за любые ошибки, опечатки и неточности,
				которые могут быть обнаружены в материалах, содержащихся на данном
				Сайте. Администрация сайта прикладывает все необходимые усилия, чтобы
				обеспечить точность и достоверность представляемой на Сайте информации.
				5.2. Информация на Сайте постоянно обновляется и в любой момент может
				стать устаревшей. Администрация сайта не несет ответственности за
				получение устаревшей информации с Сайта, а также за неспособность
				Пользователя получить обновления хранящейся на Сайте информации. 5.3.
				Администрация сайта не несет никакой ответственности за высказывания и
				мнения посетителей сайта, оставленные в качестве комментариев или
				обзоров. Мнение Администрация сайта может не совпадать с мнением и
				позицией авторов обзоров и комментариев. В то же время Администрация
				сайта принимает все возможные меры, чтобы не допускать публикацию
				сообщений, нарушающих действующее законодательство или нормы морали.
				5.4. Администрация сайта не несет ответственности за возможные
				противоправные действия Пользователя относительно третьих лиц, либо
				третьих лиц относительно Пользователя. 5.5. Администрация сайта не несет
				ответственности за высказывания Пользователя, произведенные или
				опубликованные на Сайте. 5.6. Администрация сайта не несет
				ответственности за ущерб, убытки или расходы (реальные либо возможные),
				возникшие в связи с настоящим Сайтом, его использованием или
				невозможностью использования. 5.7. Администрация сайта не несет
				ответственности за утерю Пользователем возможности доступа к своему
				аккаунту — учетной записи на Сайте. 5.8. Администрация сайта не несет
				ответственности за неполное, неточное, некорректное указание
				Пользователем своих данных при создании учетной записи Пользователя.
				5.9. При возникновении проблем в использовании Сайта, несогласия с
				конкретными разделами Пользовательского соглашения, либо получении
				Пользователем недостоверной информации от третьих лиц, либо информации
				оскорбительного характера, любой иной неприемлемой информации,
				пожалуйста, обратитесь к администрации Сайта для того, чтобы
				Администрация сайта могла проанализировать и устранить соответствующие
				дефекты, ограничить и предотвратить поступление на Сайт нежелательной
				информации, а также, при необходимости, ограничить либо прекратить
				обязательства по предоставлению своих услуг любому Пользователю и
				клиенту, умышленно нарушающему предписания Соглашения и функционирование
				работы Сайта. 5.10. В целях вышеизложенного Администрация сайта
				оставляет за собой право удалять размещенную на Сайте информацию и
				предпринимать технические и юридические меры для прекращения доступа к
				Сайту Пользователей, создающих согласно заключению Администрация сайта,
				проблемы в использовании Сайта другими Пользователями, или
				Пользователей, нарушающих требования Соглашения. 6. Порядок действия
				Соглашения 6.1. Настоящее Соглашение является договором. Администрация
				сайта оставляет за собой право как изменить настоящее Соглашение, так и
				ввести новое. Подобные изменения вступают в силу с момента их размещения
				на Сайте. Использование Пользователем материалов сайта после изменения
				Соглашения автоматически означает их принятие. 6.2. Данное Соглашение
				вступает в силу при первом посещении Сайта Пользователем и действует
				между Пользователем и Компанией на протяжении всего периода
				использования Пользователем Сайта. 6.3. Сайт является объектом права
				интеллектуальной собственности Администрации сайта. Все исключительные
				имущественные авторские права на сайт принадлежат Администрации сайта.
				Использование сайта Пользователями возможно строго в рамках Соглашения и
				законодательства РФ о правах интеллектуальной собственности. 6.4. Все
				торговые марки и названия, на которые даются ссылки в материалах
				настоящего Cайта, являются собственностью их соответствующих владельцев.
				6.5. Пользователь соглашается не воспроизводить, не повторять, не
				копировать, какие-либо части Сайта, кроме тех случаев, когда такое
				разрешение дано Пользователю Администрацией сайта. 6.6. Настоящее
				Соглашение регулируется и толкуется в соответствии с законодательством
				РФ. Вопросы, не урегулированные Соглашением, подлежат разрешению в
				соответствии с законодательством РФ. Защита персональных данных
				Уважаемый Пользователь, регистрируясь на нашем сайте или используя
				различные сервисы нашего сайта, которые требуют ввода Ваших личных
				данных, Вы даёте согласие на обработку своих персональных данных
				согласно ФЗ-152 «О защите персональных данных», а также согласованным и
				утвержденным Правилам. Администрация сайта оставляет за собой право на
				использование данной информации в маркетинговых целях в рамках
				предоставления услуг. Пользователь, регистрируясь на сайте ___________
				(далее – «Сайт»), в том числе, создавая свой аккаунт и/или регистрируясь
				в качестве пользователя каких-либо сервисов Сайта, в случае
				осуществления соответствующих действий под собственным и действительным
				(а не выдуманным) именем, дает свое согласие собственнику Сайта на
				обработку персональных данных Пользователя (ФИО, пол, возраст,
				местожительства, дата и место рождения, контактных данных в случае
				предоставления такой информации и при условии, что такая информация
				является правдивой и действительной). Пользователь дает согласие
				собственнику Сайта на осуществление действий в информационной
				(автоматизированной) системе и/или в картотеках персональных данных,
				которые связаны со сбором, регистрацией, накоплением, хранением,
				адаптированием, изменением, обновлением, использованием и
				распространением (реализацией, передачей), обезличением, уничтожением
				соответствующих персональных данных и ведомостей о Пользователе –
				физическом лице. Пользователь также дает собственнику Сайта право
				определять на собственное усмотрение порядок использования персональных
				данных Пользователя (в том числе порядок использования персональных
				данных работниками собственника Сайта в соответствии с их
				профессиональными или служебными, или трудовыми обязанностями), порядок
				защиты, распространения, порядок доступа к базам данных и порядок
				передачи права обработки персональных данных другими субъектами
				отношений, связанных с персональными данными. Пользователь предоставляет
				указанные права собственнику Сайта с целью соблюдения последним
				требований действующего законодательства о защите персональных данных Не
				забудьте разместить в Пользовательском соглашении Ваш товарный знак!
				Чтобы сохранить правовую охрану товарного знака, компании придется
				доказать, что она его использует. При этом использованием считается не
				любое действие правообладателя - в том числе размещение ТЗ на
				Пользовательском соглашении, заставках сайта, водяных знаках контента
				сайта. Суд учтет только те действия, из которых видно, что товар со
				спорным обозначением ввели в гражданский оборот. Например, компания
				смогла доказать, что продукцию ввели в оборот, с помощью товарных
				накладных и счетов-фактур. Также суд принял довод о том, что ответчик
				реализовывал товар со спорным обозначением крупным торговым сетям в
				больших объемах. П. 38 Обзора судебной практики по делам, связанным с
				разрешением споров о защите интеллектуальных прав, утв. Президиумом ВС
				23.09.2015 Решение СИП от 07.02.2022 по делу № СИП-946/2021 При этом
				суды не считают, что правообладатель предпринял действия, чтобы ввести
				товар в гражданский оборот, если он передавал продукцию по договору
				поставки только своим аффилированным лицам. Например, ИП пытался
				доказать, что использует товарный знак через лицензионный договор и
				договор поставки. Суд не принял эти документы. Указал: товары передавали
				по договору поставки, который заключили между аффилированными с
				правообладателем лицами, и в нем нет сведений, что продукция содержала
				спорное обозначение. Помимо этого суд не принял в качестве
				доказательства декларации соответствия, поскольку само по себе
				декларирование товаров не свидетельствует, что их ввели в оборот. Также
				суды не считают использованием товарного знака тот факт, что третье лицо
				производит товар с маркировкой и передает его правообладателю. Решение
				СИП от 26.03.2021 по делу № СИП-750/2020 Постановление Президиума СИП от
				20.02.2015 по делу № СИП-655/2014 Когда суды решают вопрос, использовал
				ли правообладатель товарный знак, они могут учесть объем товарооборота.
				Так, низкий уровень товарооборота в совокупности с другими документами
				признают достаточным доказательством того, что правообладатель
				использует товарный знак, если продукция дорогостоящая. Если же товар
				дешевый и доступен широкому кругу потребителей, то низкий объем
				товарооборота будет аргументом не в пользу правообладателя. Например,
				суд прекратил правовую охрану товарного знака в отношении средств
				бытовой химии, поскольку за восемь лет его действия правообладатель ввел
				в оборот только шесть экземпляров продукции. Постановление Президиума
				СИП от 20.04.2017 по делу № СИП-662/2016 Постановление Президиума СИП от
				16.01.2017 по делу № СИП-185/2016 Допускается использовать товарный знак
				с небольшими изменениями, которые не меняют его существа. Например,
				ответчик зарегистрировал комбинированный товарный знак «ЛЁВА».
				Использовал его для маркировки сахарной ваты и попкорна. Вместо точек
				над буквой «ё» в обозначении изображены две семечки подсолнечника.
				Ответчик использовал товарный знак в накладных без семечек. Суд указал:
				тот факт, что правообладатель использовал товарный знак в несколько
				отличном графическом исполнении, не меняет существа спорного обозначения
				и не ограничивает его охрану. Индивидуализацию товарному знаку придает
				именно оригинальный словесный, а не графический элемент. Слово «ЛЁВА»
				является оригинальным, акцентирует внимание потребителя и легче
				запоминается. На основании этого суд пришел к выводу, что ответчик
				использовал товарный знак в спорный период. Источники: п. 2 ст. 1486 ГК,
				п. 164 постановления Пленума ВС от 23.04.2019 № 10, решение СИП от
				10.09.2021 по делу № СИП-647/2019 Правообладателю придется доказать, что
				он использует товарный знак в отношении каждого товара, на который
				претендует конкурент. При этом суд не примет как доказательства
				документы, которые подтверждают, что правообладатель использует товарный
				знак на однородных товарах. К примеру, истец просит прекратить правовую
				охрану обозначения для товаров «творог», «йогурт» и «кефир». Если
				правообладатель представит только документы, которые подтверждают, что
				он использует товарный знак на других продуктах, например на молоке,
				сливках и ряженке, он спор проиграет. Эти товары хоть и похожи, но не
				подтверждают, что товарный знак используют в отношении тех категорий
				товаров, в отношении которых истец просит прекратить охрану. П. 166
				постановления Пленума ВС от 23.04.2019 № 10 Суд учтет однородность
				товаров, только если обозначение широко известное. Например, суд
				сохранил правовую охрану товарного знака в отношении однородных товаров,
				поскольку установил, что продукция ответчика широко известна. Он учел,
				что правообладатель распространяет продукцию с товарным знаком
				длительный период времени, в значительных объемах и на обширной
				территории, понес существенные затраты на его рекламу и продвижение.
				Также провели опрос потребителей, по результатам которого установили,
				что 80 процентов респондентов знают спорное обозначение и его
				производителя. Постановление Президиума Суда по интеллектуальным правам
				от 21.01. 2021 по делу № СИП-286/2020 Правообладатель вправе не
				доказывать, что использует товарный знак в отношении каждого отдельного
				наименования товара, если они сформулированы в МКТУ в форме видового или
				родового указания. Например, обозначение зарегистрировали для товаров
				30-го класса МКТУ — «кондитерские изделия, конфеты, карамель». Если
				правообладатель докажет, что использует товарный знак для карамели, суд
				примет это как доказательство использования его и в отношении конфет как
				формы карамели. П. 21 Справки по результатам обобщения судебной практики
				по рассмотрению споров о досрочном прекращении правовой охраны товарного
				знака в связи с его неиспользованием, утв. постановлением Президиума СИП
				от 24.07.2015 № СП-23/2 Прекратить правовую охрану товарного знака
				досрочно суд вправе, если правообладатель не использует его непрерывно в
				течение трех лет. Когда суд будет исчислять этот срок, он не включит в
				него период, в течение которого правообладатель не мог использовать
				обозначение по уважительным причинам. Для этого нужно доказать, что они
				возникли независимо от воли правообладателя. Например, уважительной
				причиной будет, если государство ограничит импорт или установит другие
				требования в отношении товаров и услуг, для которых правообладатель
				зарегистрировал товарный знак. При этом банкротство и смену
				правообладателя суды не считают уважительными причинами, чтобы не
				использовать товарный знак. Источники: п. 167 постановления Пленума ВС
				от 23.04. 2019 № 10, п. 19 Справки по результатам обобщения судебной
				практики по рассмотрению споров о досрочном прекращении правовой охраны
				товарного знака в связи с его неиспользованием, утв. постановлением
				Президиума СИП от 24.07.2015 № СП-23/20 Если компания представит
				доказательства, что товарный знак использует другое лицо по
				лицензионному договору либо под контролем правообладателя, этот аргумент
				также поможет сохранить правовую охрану обозначения. Третье лицо
				использует товарный знак под контролем, если оно действует в
				соответствии с волей правообладателя, которую последний может выразить в
				договоре или в иной форме. Помимо лицензионного договора правообладатель
				и третье лицо могут заключить договоры коммерческой концессии, простого
				товарищества, подряда или возмездного оказания услуг. Эти виды
				соглашений суды в совокупности с другими документами принимают в
				качестве доказательства того, что товарный знак использует третье лицо
				под контролем правообладателя. Подтвердить факт использования товарного
				знака удается и через действия аффилированных с правообладателем лиц. К
				примеру, ИП требовал досрочно прекратить правовую охрану товарного знака
				иностранного производителя музыкального оборудования. Ответчик смог
				доказать, что спорное обозначение используют на территории РФ. Он
				представил в суд правоустанавливающие документы российской компании,
				которая является его аффилированным лицом и официальным дистрибьютором
				продукции на территории РФ. Также суд принял в качестве доказательства
				договоры на оказание услуг и выполнение работ, на рекламные услуги, акты
				сдачи-приемки и отчеты. Суд отказал в иске и сохранил правовую охрану
				товарному знаку. Постановление Президиума СИП от 21. 06.2021 по делу №
				СИП-13/2020 Также суды признают, что товарный знак под контролем
				правообладателя использует третье лицо, когда оно размещает обозначение
				в сети Интернет, чтобы распространять товары. Если такое лицо
				администрирует доменное имя, в котором содержится спорное обозначение,
				это тоже считается использованием товарного знака. Например, в споре о
				досрочном прекращении охраны обозначения ответчик ссылался на то, что
				товарный знак использует в доменном имени третье лицо под его контролем.
				Первая инстанция не приняла этот довод. Кассация не согласилась с
				решением и направила дело на новое рассмотрение. Указала: суд не выявил,
				использует ли третье лицо спорный товарный знак в доменном имени и
				происходит ли это под контролем правообладателя. При новом рассмотрении
				суд установил, что в спорный период товарный знак использовал в домене
				предыдущий правообладатель, с которым ответчик входит в одну группу
				компаний. На основании этого суд отказал в исковых требованиях. Не
				забудьте об изменений в законодательстве о персональных данных С 1
				сентября вступают в силу новые требования к обработке персональных
				данных. Поправки не только предусматривают новые обязанности и запреты
				для операторов данных, но и сокращают сроки для ответов на запросы
				Роскомнадзора и граждан. Что изменилось в обработке данных
				несовершеннолетних Новый закон запрещает включать в договор с субъектом
				персональных данных три вида условий. Речь о положениях, которые
				ограничивают права и свободы субъекта, допускают в качестве условия
				заключения договора бездействие субъекта, а также устанавливают случаи
				обработки данных несовершеннолетних.152ФЗ Если первые два вида положений
				— не новые, то третий дополнительно ограничивает компании. Скорее всего,
				Роскомнадзор будет толковать его расширительно: персональные данные
				несовершеннолетних нельзя обрабатывать на основании договора, если иное
				прямо не указано в законе. Владельцы цифровых платформ больше не смогут
				ссылаться на то, что несовершеннолетний принял все правила пользования
				ресурса, а значит, дал основание для обработки. Многим сервисам придется
				переоценить свои риски и пересмотреть подход к обработке данных. При
				этом важно, что предоставление согласия на обработку — односторонняя
				сделка. Следовательно, требования к форме согласия будут отличаться для
				разных возрастных групп. Что проверить в согласии на обработку данных
				Появились дополнительные критерии, которым должно соответствовать
				согласие на обработку персональных данных. Теперь оно должно быть не
				только конкретным, информированным и сознательным, а еще и предметным и
				однозначным.152ФЗ-1 Однако Роскомнадзор уже давно указывает на то, что
				субъект данных должен выразить согласие однозначным действием. Поэтому
				поправка лишь закрепляет подход, который есть в практике. Еще один
				подход, который уже давно выработала практика, но который теперь есть в
				законе: запрет отказывать в услуге из-за того, что гражданин не
				предоставил биометрические данные или согласие на их обработку.
				Предоставление биометрических персональных данных не может быть
				обязательным. Какие требования теперь есть к поручению на обработку
				данных Теперь в поручении оператора на обработку данных должен быть
				перечень персональных данных.152ФЗ-2 Операторам придется выбирать:
				указывать широкие общие или четкие узкие категории данных. Каждый из
				вариантов несет свой риск. Так, в первом случае есть вероятность, что
				практика не будет толковать категории так же широко, как оператор и
				обработчик данных. Но если выбрать наиболее детальные категории,
				поручение вероятно придется часто редактировать, поскольку объем данных
				может меняться. В поручение оператора на обработку персональных данных
				необходимо включать и обязанности обработчика: во-первых, соблюдать
				требования, предусмотренные частью 5 статьи 18 и статьей 18.1 Закона о
				персональных данных, во-вторых, предоставлять по запросу оператора в
				течение срока действия поручения информацию, подтверждающую принятие мер
				и соблюдение установленных требований, в-третьих, информировать
				Роскомнадзор об инцидентах. Кроме того, теперь, если оператор поручает
				обработку иностранному лицу, ответственность за его действия перед
				субъектом данных будут нести и оператор, и обработчик. Такая поправка
				повысит внимание Роскомнадзора к иностранным компаниям. СУДЕБНАЯ
				ПРАКТИКА ПО ПОЛЬЗОВАТЕЛЬСКОМУ СОГЛАШЕНИЮ ВС признал пользовательское
				соглашение доказательством вины Согласие с правилами пользования
				соцсети, которые запрещают публиковать противозаконную информацию,
				подтверждает, что размещение на личной странице запрещённых материалов
				имеет умысел на их незаконный оборот: действующим законодательством
				пользовательское соглашение рассматривается как публичная оферта,
				разъясняет Верховный суд РФ. Сам по себе факт, что пользователь соцсети
				спорный видеофайл никому не предлагает и не передаёт, а лишь размещает
				на него ссылку или делает репост, не свидетельствует об отсутствии у
				него умысла на незаконный оборот запрещённых материалов, поскольку
				своими действиями он позволяет другим пользователям просматривать
				содержание запрещённой информации, отмечает высшая инстанция. Суть дела
				Согласно материалам дела, фигурант обвинялся в распространении и
				публичной демонстрации порнографических материалов в интернете: обладая
				знаниями в области информационных технологий и навыками пользователя
				компьютерной техники, являясь зарегистрированным пользователем
				социальной сети под псевдонимом, обвиняемый разместил на своей странице
				для неограниченного круга пользователей в доступном для всеобщего
				обозрения и копирования разделе «Мои видеозаписи» ссылку на видеофайл,
				содержащий в соответствии с заключением экспертов информацию
				порнографического содержания. При этом осужденный не ограничил доступ
				других пользователей к своей странице со ссылкой на видеофайл и тот
				длительное время сохранялся в свободном доступе, создав возможность
				просмотра неограниченным количеством пользователей. Суд первой инстанции
				вынес обвинительный приговор, с которым согласилась апелляционная
				инстанция. Не оспаривая фактические обстоятельства уголовного дела, суд
				кассационной инстанции прекратил по нему производство, указав, что
				поскольку загруженный фигурантом видеофайл уже был распространен в сети
				Интернет и находился в свободном доступе, а сам обвиняемый никому его не
				предлагал и не передавал, то, следовательно, у него отсутствовал умысел
				на незаконный оборот порнографических материалов. В высшую инстанцию с
				протестом обратился замгенпрокурора. Позиция ВС Суд кассационной
				инстанции не учел, что распространение материалов с порнографическим
				изображением, образующее объективную сторону преступления по статье 242
				УК РФ, может быть осуществлено посредством использования
				информационно-телекоммуникационной сети - технологической системы,
				предназначенной для передачи по линиям связи информации, доступ к
				которой осуществляется с использованием средств вычислительной техники.
				Распространение информации представляет собой как передачу информации
				неопределенному кругу лиц, так и действия, направленные на получение
				информации неопределенным кругом лиц. Исходя из принципа работы сайта,
				который представляет собой информационный ресурс, предназначенный для
				общения пользователей в сети «Интернет», доступ к сайту и возможность
				размещать на нем на своей социальной странице различные объекты
				возникают после заключения обязательного пользовательского соглашения,
				предусмотренного Правилами пользования сайтом. Указанные Правила,
				которые действующим законодательством рассматриваются как публичная
				оферта (статья 437 ГК РФ), определяют условия использования сайта и
				принятие пользователем на себя указанных в нем прав и обязанностей,
				выработанных в целях недопущения размещения, в том числе на персональной
				странице пользователя, информации, которая нарушает права и интересы
				граждан или требования законодательства. Правила пользования сайтом
				регламентируют, что пользователь самостоятельно определяет содержание
				собственной персональной страницы и условия доступа к ней других
				пользователей; осознает и соглашается с тем, что, размещенная на
				персональной странице информация может быть доступна другим
				пользователям сети Интернет. Кроме того, Правила предусматривают запрет
				на размещение на персональной странице информации и объектов (включая
				ссылки на них), которые могут нарушать права и интересы других лиц и
				подлежат предварительной оценке пользователем на предмет законности их
				размещения; в случае наличия сомнений в этом необходимо воздерживаться
				от осуществления тех или иных действий. А также запрещено загружать,
				публиковать, распространять и предоставлять доступ или иным образом
				использовать любую информацию, которая является вульгарной, непристойной
				и нарушает требования законодательства. «Персональная ответственность и
				возможность привлечения к ответственности за нарушение действующего
				законодательства разъясняются (в Правилах). Ознакомление (фигуранта) с
				Правилами подтверждено фактом его регистрации на сайте под вымышленным
				именем. Таким образом, судом кассационной инстанции оставлено без
				внимания, что (осуждённый), будучи осведомленным об ответственном
				размещении любой информации (в том числе ссылок, репостов) на
				персональной странице и возможности наступления правовых последствий,
				проигнорировал Правила, разместил и длительное время хранил на ней в
				открытом доступе ссылку на видеофайл порнографического характера,
				оставив возможность доступа к своей странице любого пользователя сети
				«Интернет», при этом ограничения на доступ к своей странице им не
				устанавливались», - указывает ВС. Сам по себе факт, что обвиняемый
				видеофайл порнографического характера никому не предлагал и не
				передавал, о чем указано в кассационном определении, а лишь разместил на
				него ссылку в открытом доступе на своей странице, не свидетельствует об
				отсутствии у него умысла на незаконный оборот порнографических
				материалов, поскольку он позволял другим пользователям сети «Интернет»
				просматривать содержание указанного видеофайла, что, как установлено
				судом, и имело место, отмечает высшая инстанция. «По смыслу закона,
				публичная демонстрация с использованием электронных или
				информационно-телекоммуникационных сетей, включая сеть Интернет,
				заключается в открытом показе порнографических материалов, либо
				предоставление неограниченному числу лиц возможности просмотра таких
				материалов, и следовательно, как демонстрация может расцениваться как
				совершенные в прямом эфире, так и состоящие в размещении информации
				(материалов, сведений) на личных страницах и в группах (в социальных
				сетях или на интернет-страницах), в том числе репост - размещение ссылки
				непосредственно на информацию в источнике первичного размещения», -
				поясняет ВС. Кроме того, кассационная инстанция оставила без внимания,
				что фигурант был признан виновным и осужден не только за распространение
				порнографических материалов, но и за их публичную демонстрацию, однако
				каких-либо суждений по данной части обвинения не привел.
			</p>
		</div>
	)
}

export default UserAcception
